import _mapiResponse from "../classes/mapi-response";
import _mapiError from "../classes/mapi-error";
import _constants from "../constants";
import _parseHeaders from "../helpers/parse-headers";
var exports = {};
var MapiResponse = _mapiResponse;
var MapiError = _mapiError;
var constants = _constants;
var parseHeaders = _parseHeaders; // Keys are request IDs, values are XHRs.

var requestsUnderway = {};

function browserAbort(request) {
  var xhr = requestsUnderway[request.id];
  if (!xhr) return;
  xhr.abort();
  delete requestsUnderway[request.id];
}

function createResponse(request, xhr) {
  return new MapiResponse(request, {
    body: xhr.response,
    headers: parseHeaders(xhr.getAllResponseHeaders()),
    statusCode: xhr.status
  });
}

function normalizeBrowserProgressEvent(event) {
  var total = event.total;
  var transferred = event.loaded;
  var percent = 100 * transferred / total;
  return {
    total: total,
    transferred: transferred,
    percent: percent
  };
}

function sendRequestXhr(request, xhr) {
  return new Promise(function (resolve, reject) {
    xhr.onprogress = function (event) {
      request.emitter.emit(constants.EVENT_PROGRESS_DOWNLOAD, normalizeBrowserProgressEvent(event));
    };

    var file = request.file;

    if (file) {
      xhr.upload.onprogress = function (event) {
        request.emitter.emit(constants.EVENT_PROGRESS_UPLOAD, normalizeBrowserProgressEvent(event));
      };
    }

    xhr.onerror = function (error) {
      reject(error);
    };

    xhr.onabort = function () {
      var mapiError = new MapiError({
        request: request,
        type: constants.ERROR_REQUEST_ABORTED
      });
      reject(mapiError);
    };

    xhr.onload = function () {
      delete requestsUnderway[request.id];

      if (xhr.status < 200 || xhr.status >= 400) {
        var mapiError = new MapiError({
          request: request,
          body: xhr.response,
          statusCode: xhr.status
        });
        reject(mapiError);
        return;
      }

      resolve(xhr);
    };

    var body = request.body; // matching service needs to send a www-form-urlencoded request

    if (typeof body === "string") {
      xhr.send(body);
    } else if (body) {
      xhr.send(JSON.stringify(body));
    } else if (file) {
      xhr.send(file);
    } else {
      xhr.send();
    }

    requestsUnderway[request.id] = xhr;
  }).then(function (xhr) {
    return createResponse(request, xhr);
  });
} // The accessToken argument gives this function flexibility
// for Mapbox's internal client.


function createRequestXhr(request, accessToken) {
  var url = request.url(accessToken);
  var xhr = new window.XMLHttpRequest();
  xhr.open(request.method, url);
  Object.keys(request.headers).forEach(function (key) {
    xhr.setRequestHeader(key, request.headers[key]);
  });
  return xhr;
}

function browserSend(request) {
  return Promise.resolve().then(function () {
    var xhr = createRequestXhr(request, request.client.accessToken);
    return sendRequestXhr(request, xhr);
  });
}

exports = {
  browserAbort: browserAbort,
  sendRequestXhr: sendRequestXhr,
  browserSend: browserSend,
  createRequestXhr: createRequestXhr
};
export default exports;